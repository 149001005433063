import { IRoadmapDocument } from '@/interfaces/IOrganization'
import { ISubmissionFilters } from '@/interfaces/ISubmission'
import { ICustomer } from '@/interfaces/IUser'
import { atom } from 'jotai'

export const submissionActiveFilterURIAtom = atom('')
export const submissionUrlCache = atom('')

export const fetchedOrgUserAtom = atom('')
export const fetchedOrgUserResults = atom<ICustomer[]>([])
export const roadmapCurrentSubmissionParentFilterAtom = atom<ISubmissionFilters | undefined>(
  undefined
)
export const activeSubmissionIdsAtom = atom<string[]>([])
export const askAiQueryAtom = atom('')
export const askAiPopupAtom = atom(false)
export const activeFilterExistsAtom = atom(false)
export const activeRoadmapAtom = atom<IRoadmapDocument | undefined>(undefined)

import { IHelpCenterIcon } from '@/interfaces/IHelpCenter'
import axiosClient from 'network/apiClient'

export interface KnowledgeBaseCreateRequest {
  title: string
  description?: string
  body?: string
  parentId?: string
  icon?: IHelpCenterIcon
}
export interface KnowledgeBaseEditRequest {
  title?: string
  description?: string
  body?: string
  icon?: IHelpCenterIcon | null
  // state: string
  articleId: string
  locale: string
  parentId?: string | null
  authorId?: string
}

export interface KnowledgeBaseCollectionUpdateRequest {
  collectionId: string
  name?: string
  description?: string
  locale: string
  icon?: IHelpCenterIcon | null
  parentId?: string | null
}

export interface KnowledgeBaseCollectionCreateRequest {
  name: string
  description?: string
  icon?: IHelpCenterIcon | null
  parentId?: string | null
}

export interface reorderHelpdocsRequest {
  movedItemId: string
  movedItemIndex: number
  parentId?: string | null
  movedItemType: string
}

export interface UpdateHelpCenterRequest {
  displayName?: string
  title?: string
  description?: string
  locale?: string
  defaultLocale?: string
  isPublic?: boolean
  navItems?: Array<{
    icon: IHelpCenterIcon | null
    title: string
    url: string
  }>
  urls?: {
    featurebaseSubpath?: string
    externalDomain?: string
  }
  enabled?: boolean
}

// collectionId: Joi.string().custom(alphanumericId).required(),
// name: Joi.string(),
// description: Joi.string().allow(''),
// locale: Joi.string().custom(isLocale).required(),
// icon: iconSchema.allow(null),
// parentId: Joi.string().custom(alphanumericId),

export function createHelpdocs(data: KnowledgeBaseCreateRequest) {
  return axiosClient.post('/v1/helpcenter/articles', data)
}

export function getSingleHelpCenterArticleWithLocale(articleId: string, locale: string) {
  return axiosClient.get(
    `/v1/helpcenter/articles?articleId=${articleId}&locale=${locale}&state=draft`
  )
}

export function updateHelpdocs(data: KnowledgeBaseEditRequest) {
  return axiosClient.patch('/v1/helpcenter/articles', data)
}

export interface RemoveHelpCenterLocalesRequest {
  localesToRemove: string[]
  locale?: string
}

export function removeHelpCenterLocales(data: RemoveHelpCenterLocalesRequest) {
  return axiosClient.delete('/v1/helpcenter/locales', { data: data })
}

export function updateHelpdocsCollection(data: KnowledgeBaseCollectionUpdateRequest) {
  return axiosClient.patch('/v1/helpcenter/collections', data)
}

export function createHelpdocsCollection(data: KnowledgeBaseCollectionCreateRequest) {
  return axiosClient.post('/v1/helpcenter/collections', data)
}

export function publishHelpdocs(data: { articleIds: string[]; locale: string }) {
  return axiosClient.post('/v1/helpcenter/articles/publish', data)
}

export function unPublishHelpdocs(data: { articleIds: string[]; locale: string }) {
  return axiosClient.post('/v1/helpcenter/articles/unpublish', data)
}

export function deleteHelpdocs(data: { articleId: string }) {
  return axiosClient.delete('/v1/helpcenter/articles', { data: data })
}

export function deleteHelpdocsCollection(data: { collectionId: string }) {
  return axiosClient.delete('/v1/helpcenter/collections', { data: data })
}

export function reorderHelpdocs(data: reorderHelpdocsRequest) {
  return axiosClient.post('/v1/helpcenter/reorder', data)
  // Get single changelog
}
export function getSingleHelpCenterArticle(id: string, helpCenterId?: string, locale?: string) {
  const url = helpCenterId
    ? `/v1/helpcenter/articles?page=1&articleId=${id}&helpCenterId=${helpCenterId}${
        locale && `&locale=${locale}`
      }`
    : `/v1/helpcenter/articles?page=1&articleId=${id}${locale && `&locale=${locale}`}`
  return axiosClient.get(url)
}

export function updateHelpCenter(data: UpdateHelpCenterRequest) {
  return axiosClient.patch('/v1/helpcenter', data)
}

export function getSimilarPostsAndArticles(data: any, searchKey: string) {
  return axiosClient.post(
    `${process.env.NEXT_PUBLIC_MEILISEARCH_HOST}/multi-search`,
    {
      queries: data,
    },
    {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${searchKey}`,
      },
    }
  )
}

import React from 'react'

const FeedbackWidgetCard: React.FC<{
  title: string
  description: string
  index: number
  fullName: string
  setActivePage: (value: React.SetStateAction<string>) => void
}> = ({ title, description, fullName, index, setActivePage }) => {
  return (
    <button
      onClick={() => setActivePage(fullName)}
      className="relative flex items-center w-full gap-3 p-3 text-base font-medium rounded-md text-gray-600 dark:text-white border white-bg-hover group"
    >
      {title && (
        <div className="px-1 py-0.5 group-hover:shadow-none main-transition group-hover:dark:bg-white/10 dark:bg-white/5 bg-gray-100/40  group-hover:bg-gray-100/60 rounded-md">
          {title}
        </div>
      )}
      <div className="flex justify-between w-full">
        {description}
        <code className="pl-3 pr-2  my-auto text-sm border-l pointer-events-none code dark:border-white/[5%] bg-transparent dark:bg-transparent dark:!text-foreground/60 rounded-none !text-background-accent/80">
          {index}
        </code>
      </div>
    </button>
  )
}
export default FeedbackWidgetCard

import AnimateDown, { AnimateDownChild } from '@/components/AnimateDown'
import CreatePostSimilarSuggestion from '@/components/CreatePostSimilarSuggestion'
import FeedbackWidgetCard from '@/components/FeedbackWidgetCard'
import Loader from '@/components/Loader'
import TextEditor from '@/components/TextEditor'
import { useCurrentOrganization } from '@/data/organization'
import { useUser } from '@/data/user'
import { sanitizeHTML } from '@/lib/contentSanitizer'
import { cn } from '@/lib/utils'
import { ChevronLeftIcon, ExternalLinkIcon, XIcon } from '@heroicons/react/solid'
import { Editor } from '@tiptap/react'
import { uploadImageCloudflare, uploadScreenshot } from 'network/lib/organization'
import { createSubmission } from 'network/lib/submission'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { z } from 'zod'
import { getBoardUrl } from './changelogPopup'
import { getCompiledTheme, getDarkerPrimaryColor } from '@/components/CustomThemeHandler'
import chroma from 'chroma-js'
import { ISubmission } from '@/interfaces/ISubmission'
import useElementSize from '@/hooks/betterUseElementSize'
import { useAtom } from 'jotai'
import { metaDataAtom } from '@/atoms/orgAtom'
import PostCreationCustomFields from '@/components/PostCreationCustomFields'
import { ICustomInputValues } from '@/interfaces/IOrganization'
import { validateCustomFields } from '@/components/CreatePost'
import { useTranslation } from 'next-i18next'
import Tinybird from '@/components/Tinybird'

export function getFirstEmoji(str: string) {
  // @ts-ignore
  const regex = /(\p{Emoji}\uFE0F|\p{Emoji_Presentation})/gu
  let match = str.match(regex)
  return match ? match[0] : null
}
export function removeEmojis(str: string) {
  // @ts-ignore
  const regex = /(\p{Emoji}\uFE0F|\p{Emoji_Presentation})/gu
  let result = str.replace(regex, '')
  return result
}

const FeedbackWidget = ({
  email,
  defaultTheme,
  defaultBoard,
}: {
  email: string
  defaultTheme: string
  defaultBoard: string
}) => {
  const { org } = useCurrentOrganization()
  const [activePage, setActivePage] = React.useState(
    defaultBoard && org.postCategories.find((cat) => cat.category === defaultBoard)
      ? defaultBoard
      : 'main'
  )

  const activeCategory = org?.postCategories.find((cat) => cat?.category === activePage)

  const [formData, setFormData] = React.useState({
    content: '',
    title: '',
    email: email,
    customInputValues: activeCategory?.customInputFields?.reduce(
      (acc: ICustomInputValues, field) => {
        if (acc?.[field]) return acc
        return { ...acc, [field]: '' }
      },
      {}
    ),
  })

  const [cards, setCards] = React.useState<
    | {
        title: string
        description: string
        fullName: string
        placeholder: string
        index: number
      }[]
    | []
  >(
    org?.postCategories
      ?.filter((cat) => !cat.private && !cat.disablePostCreation)
      ?.map((category, index) => ({
        title: getFirstEmoji(category.category) || '',
        description: removeEmojis(category.category),
        fullName: category.category,
        placeholder: category?.prefill || '',
        index: index + 1,
      })) || []
  )

  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [loadingImage, setLoadingImage] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const loadingRef = useRef(loading)
  const inputRef = useRef<any>()
  const [errorTitle, setErrorTitle] = React.useState(false)
  const [errorEmail, setErrorEmail] = React.useState(false)
  const [isFreeOrg, setIsFreeOrg] = React.useState(false)
  const [parentRef, { height: parentHeight }] = useElementSize()
  // const [suggestionHeightRef, { height: suggestionHeight }] = useElementSize()
  const editorRef: React.MutableRefObject<Editor | null> = useRef(null)
  const [newSubmission, setNewSubmission] = useState<ISubmission | null>(null)
  const oldContentRef = useRef('')
  const activePageRef = useRef(activePage)
  const [metaData, setMetaData] = useAtom(metaDataAtom)
  const [customInputFieldErrors, setCustomInputFieldErrors] = useState<{
    [key: string]: boolean
  }>({})

  const [renderStatistics, setRenderStatistics] = useState(false)

  const { user } = useUser()

  const feedbackSchema = z.object({
    title: z.string().min(3, 'Title must be at least 3 characters long'),
    email: z.string().email('Must be a valid email').optional().or(z.literal('')),
    content: z.string().optional(), // if needed
  })

  useEffect(() => {
    if (org) {
      setCards(
        org?.postCategories
          ?.filter((cat) => !cat.private && !cat.disablePostCreation)
          .map((category, index) => ({
            title: getFirstEmoji(category.category) || '',
            description: removeEmojis(category.category),
            fullName: category.category,
            placeholder: category?.prefill || '',
            index: index + 1,
          }))
      )
    }
  }, [org])

  // Listen for keydown events for number keys and set activePage accordingly
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // If the key pressed is a number key
      if (e.key.match(/[0-9]/)) {
        if (activePage !== 'main') return
        if (parseInt(e.key) > cards.length) return
        setActivePage(e.key === '0' ? 'main' : cards[parseInt(e.key) - 1]?.fullName)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [cards, activePage])

  useEffect(() => {
    activePageRef.current = activePage
    if (activePage !== 'main') {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)

      const category = org?.postCategories.find((cat) => cat?.category === activePage)

      setFormData({
        content: '',
        title: '',
        email: email,
        customInputValues: category?.customInputFields?.reduce((acc: ICustomInputValues, field) => {
          if (acc?.[field]) return acc
          return { ...acc, [field]: '' }
        }, {}),
      })
    }
  }, [activePage])

  useEffect(() => {
    loadingRef.current = loading
  }, [loading])

  useEffect(() => {
    if (submitting) {
      setSubmitting(false)
      submitFeedback(true)
    }
  }, [submitting])

  const sanitizedPrefill = sanitizeHTML(
    cards?.find((cat) => cat.fullName === activePage)?.placeholder || t('describe-your-request'),
    false
  )

  useEffect(() => {
    if (org) {
      if (org?.plan === 'free' && new Date(org?.createdAt) > new Date('2023-08-13')) {
        setIsFreeOrg(true)
      }

      const customTheme: any = defaultTheme !== 'light' ? getCompiledTheme('dark', org, true) : null

      let outputColor = ''
      let borderColor = ''
      let primaryForeground = ''

      if (customTheme) {
        const [h, s, l] = customTheme?.background
          .split(' ')
          .map((n: any, i: number) => parseFloat(n) / (i === 0 ? 1 : 100))
        const originalColor = chroma.hsl(h, s, l).rgb().join(',')

        const [h1, s1, l1] = customTheme?.border
          .split(' ')
          .map((n: any, i: number) => parseFloat(n) / (i === 0 ? 1 : 100))
        const borderOrginial = chroma.hsl(h1, s1, l1).rgb().join(',')

        outputColor = originalColor
        borderColor = borderOrginial
      }

      const [h2, s2, l2] = getDarkerPrimaryColor(org)
        .split(' ')
        .map((n: any, i: number) => parseFloat(n) / (i === 0 ? 1 : 100))
      const primaryForegroundOriginal = chroma.hsl(h2, s2, l2).rgb().join(',')
      primaryForeground = primaryForegroundOriginal

      window.parent.postMessage(
        {
          target: 'FeaturebaseWidget',
          data: {
            action: 'initializeOrg',
            data: {
              color: org?.color,
              background: outputColor ? outputColor : '',
              borderColor: borderColor,
              primaryForeground: primaryForeground,
            },
          },
        },
        '*'
      )
    }
  }, [org])

  const submitFeedback = async (imageSubmit = false) => {
    if (!imageSubmit) {
      if (loadingRef.current) return
      if (loadingImage) {
        setLoading(true)
        return
      }
    }

    if (!org) return

    try {
      // validate formData with the schema
      const validatedData = feedbackSchema.parse(formData)

      // Validate custom fields
      if (
        !validateCustomFields(
          activeCategory?.customInputFields ? activeCategory?.customInputFields : [],
          org,
          { customInputValues: formData?.customInputValues } as any,
          setCustomInputFieldErrors
        )
      ) {
        return
      }

      if (!user && !org?.settings?.anyoneCanSubmit && !validatedData.email) {
        setErrorEmail(true)
        throw new Error('Email is required')
      }

      const modifiedCustomInputValues = formData?.customInputValues
        ? Object.fromEntries(
            Object.entries(formData?.customInputValues).map(([key, value]) => {
              return [key, value ? value : null]
            })
          )
        : {}

      // rest of your code goes here...
      setLoading(true)

      const activeSubmission = org?.postCategories.find((cat) => cat.category === activePage)

      if (activeSubmission?.category) {
        const sendingData = {
          category: {
            category: activeSubmission?.category || '',
            private: activeSubmission?.private || false,
          },
          content: validatedData.content || '',
          title: validatedData.title,
          recaptchaToken:
            '03AL8dmw_35wkL_Q5l4xFPwtO_Rtol94kExNd9RNmDjILpMOqsB1cg7fv1FlWDXwU40gYF6YEPsAkY_eM_Fd5DtrbEvVof9PLnEw8NVB7nD-kwz9TQm_6dPMniT5uKMI17Zg4hBA30CZC4N-So_Oq5d2rYBvvj785SgYrmc0upDJ-pJMDIMnoRh3XjA_atb7oEh7082MsT5DlIKjizAFIAdLOwG5YUCqny2GTE_Jyyzcg6WXomANgPihfnKMzpCNS5LsYSUwkCh9PfiwRN6RZwwi0IVn8AaXxRNKveJjg5ZlLmQKVoOFM9wZTlEKG2qUvvEhItOfscasdSEtYXCL48_IlDm1RfKNipt_CabZtWO1f0raEjkqmPn-W8pvLOyqLeT8hCQI2aSnnihWHizFlZLwMg1jflmMz1k9Dyj_mKqEZKmLW-iQpXCqoNMgwRDPq8SloslGXMw3kaOLUrD9N3CQe5YdSSyH72JMC20SJJsQLJd_k9T3mZDz3y_mxzVwhMc5F0O4g85A4fBbOeXYzmg0WlWEIN3BuRRGbipl0UShkV_I2TbwliiC665ZTmyxdsYPclSFVN33PfDWGgXvbe3IVSELmzbT0ZzIocthSw-1ysB_CjKH317DzRxHN74DxhkujlAE8M_ONeoCPnbD88ohUY6krYdLGFbg',
          metadata: metaData ? metaData : undefined,
          email: validatedData.email,
          customInputValues: modifiedCustomInputValues,
          eventOrigin: 'feedback_widget',
        }

        if (!validatedData.email) {
          delete sendingData.email
        }

        createSubmission(sendingData)
          .then((resp) => {
            setLoading(false)
            if (resp.data.success) {
              setActivePage('success')
              setFormData({ content: '', title: '', email: email, customInputValues: {} })
              setNewSubmission(resp?.data?.submission)
              window.parent.postMessage(
                {
                  target: 'FeaturebaseWidget',
                  data: {
                    action: 'feedbackSubmitted',
                    post: resp?.data?.submission,
                  },
                },
                '*'
              )
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
    } catch (error) {
      setLoading(false)
      if (error instanceof z.ZodError) {
        // Handle validation errors
        console.error(error.errors) // log the validation errors
        // you can use setErrorTitle and setErrorEmail based on the error messages.
        if (error.message.includes('title')) setErrorTitle(true)
        if (error.message.includes('email')) setErrorEmail(true)
      } else {
        // some other error occurred
      }
    }
  }

  // Listen for editor height change
  useEffect(() => {
    let action = 'menuHeightChangedFeedback'
    if (oldContentRef.current !== formData.content && formData?.content?.length > 7) {
      oldContentRef.current = formData.content
      action = 'menuHeightChangedFeedbackContent'
    }

    window.parent.postMessage(
      {
        target: 'FeaturebaseWidget',
        data: {
          action: action,
          data: parentHeight,
        },
      },
      '*'
    )
  }, [parentHeight, formData.content])

  // Listen for escape key press and close the widget
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        window.parent.postMessage(
          {
            target: 'FeaturebaseWidget',
            data: { action: 'closeWidget' },
          },
          '*'
        )
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  function base64ToBlob(base64: string) {
    const binaryString = atob(base64.split(',')[1])
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    return new Blob([bytes], { type: 'image/png' }) // you may need to modify the MIME type if you're dealing with a different type of image
  }

  const updateMetadata = useCallback(
    (newMetadata: any) => {
      setMetaData((prevMetadata) => ({
        ...prevMetadata,
        ...newMetadata,
      }))
    },
    [setMetaData]
  )

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault()
      if (!e.data || e.data.target !== 'FeaturebaseWidget') return

      switch (e.data.data.action) {
        case 'openFeedbackWidget':
          if (activePageRef?.current === 'success') {
            setActivePage('main')
          } else {
            const updateBoard = e.data.data?.setBoard
            if (updateBoard) {
              const newBoard = org.postCategories.find((cat) => cat.category === updateBoard)
                ? updateBoard
                : 'main'

              setActivePage(newBoard)
            }
          }
          break
        case 'widgetOpened':
          setRenderStatistics(true)
          break
        case 'updateMetadata':
          updateMetadata(e.data.data.metadata)
          break
        case 'screenshotCallback':
          // old flow
          if (e.data.data.image) {
            setLoadingImage(true)
            const imageBlob = base64ToBlob(e.data.data.image)
            const formData = new FormData()
            formData.append('image', imageBlob, 'screenshot.png') // assuming the image is a PNG

            uploadImageCloudflare(formData)
              .then((resp) => {
                if (resp.data.success) {
                  editorRef?.current?.commands.insertContent({
                    type: 'image',
                    attrs: {
                      src: resp.data.src,
                      style: 'max-width: 100%; height: auto;',
                    },
                  })
                }
                if (loadingRef.current) {
                  // Wait for 200ms before setting submitting to true
                  setTimeout(() => {
                    setSubmitting(true)
                  }, 400)
                }
              })
              .finally(() => {
                setLoadingImage(false)
              })
              .catch((e) => {
                setLoadingImage(false)
              })
          } else if (e.data.data.snapshot) {
            setLoadingImage(true)
            const snapshot = e.data.data.snapshot as Uint8Array
            if (!snapshot || snapshot.length === 0) return setLoadingImage(false)
            // decode JSON string

            var fd = new FormData()
            // @ts-ignore
            fd.append('snapshot', new Blob([snapshot.buffer]))
            uploadScreenshot(fd)
              .then((resp) => {
                if (resp.data.success) {
                  editorRef?.current?.commands.insertContent({
                    type: 'image',
                    attrs: {
                      src: resp.data.src,
                      style: 'max-width: 100%; height: auto;',
                    },
                  })
                }
                if (loadingRef.current) {
                  setTimeout(() => {
                    setSubmitting(true)
                  }, 400)
                }
              })
              .finally(() => {
                setLoadingImage(false)
              })
              .catch((e) => {
                setLoadingImage(false)
              })
          }
          break
        case 'numberKeyPress':
          const number = e.data.data.key
          if (activePage !== 'main') return
          if (parseInt(number) > cards.length) return
          setActivePage(number === '0' ? 'main' : cards[parseInt(number) - 1]?.fullName)
          break
        default:
          return
      }
    }

    window?.addEventListener('message', handler)

    return () => window.removeEventListener('message', handler)
  }, []) // empty array => run only once

  if (isFreeOrg) {
    return (
      <div ref={parentRef}>
        <div className="p-6 ">
          <p className="text-lg font-medium text-gray-600 dark:text-gray-50">
            Not available with the free plan
          </p>
          <p className="max-w-xs mt-1 text-sm text-gray-400 dark:text-foreground">
            Please upgrade your account to use this feature.
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className={'w-full min-h-screen bg-transparent overflow-hidden'}>
      <style>{`
        html {
          overflow: hidden;
        }
       .ProseMirror p.is-editor-empty:first-child::after {
          margin-top: -22px !important;
          line-height: 1.5rem !important;
        }
        @media (max-width: 640px) {
          .ProseMirror p.is-editor-empty:first-child::after {
            margin-top: -24px !important;
            line-height: 1.5rem !important;
          }
        }
        `}</style>
      <div ref={parentRef} className="relative w-full max-w-md mx-auto overflow-auto">
        {/* <div className="absolute inset-x-0 w-64 h-full mx-auto bottom-6 blur-[50px] bg-gradient-to-t dark:from-secondary dark:to-gray-850 from-gray-200/70 to-gray-50 transform-gpu"></div> */}
        {renderStatistics && <Tinybird module="feedback" />}
        <button
          onClick={() => {
            window.parent.postMessage(
              {
                target: 'FeaturebaseWidget',
                data: { action: 'closeWidget' },
              },
              '*'
            )
          }}
          className="absolute z-50 cursor-pointer unstyled-button right-5 top-5"
        >
          <XIcon className="relative w-5 h-5 secondary-svg" />
        </button>
        {activePage === 'main' ? (
          <div className="p-6 ">
            <p className="text-lg font-medium text-gray-600 dark:text-gray-50">
              {t('give-us-feedback')}
            </p>
            <p className="max-w-xs mt-1 text-sm text-gray-400 dark:text-foreground">
              {t('tell-us-how-we-could-make-the-product-more-useful-for-you')}
            </p>
            <div className="mt-6 ">
              <AnimateDown className="space-y-3">
                {cards?.map((card) => (
                  <AnimateDownChild key={card.index}>
                    <FeedbackWidgetCard
                      key={card.index}
                      fullName={card.fullName}
                      title={card.title}
                      description={card.description}
                      index={card.index}
                      setActivePage={setActivePage}
                    />
                  </AnimateDownChild>
                ))}
                {!org?.whitelabel && (
                  <AnimateDownChild>
                    <div className="flex items-center justify-center px-2 py-1 mt-2 -mb-3">
                      <a
                        href={`https://featurebase.app?utm_source=${org?.name}&utm_medium=feedback-widget&utm_campaign=powered-by&utm_id=${org?.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="px-1.5 py-0.5 rounded text-xs font-medium text-background-accent dark:text-foreground bg-transparent shadow-none white-btn dark:bg-transparent dark:border-transparent hover:dark:bg-border/50 rounded-mdinline-flex border-transparent hover:border-gray-100/50 hover:border-gray-100 ">
                          {t('powered-by-featurebase')}
                        </button>
                      </a>
                    </div>
                  </AnimateDownChild>
                )}
              </AnimateDown>
            </div>
          </div>
        ) : null}
        {activePage !== 'main' && activePage !== 'success' ? (
          <div className="overflow-auto">
            <div className="relative h-full p-6">
              <div className="flex gap-3 mb-5">
                <p className="text-lg font-medium text-gray-600 first-letter:uppercase dark:text-gray-50">
                  {activePage}
                </p>
              </div>

              <AnimateDown>
                <AnimateDownChild className="">
                  <input
                    id="title"
                    className={cn(
                      'bg-white/60 shadow-sm !rounded-md white-bg',
                      errorTitle &&
                        'ring-rose-200 dark:ring-offset-gray-800 dark:ring-rose-500/40 ring-offset-white  ring-offset-1 ring-2'
                    )}
                    ref={inputRef}
                    placeholder={t('add-a-title')}
                    value={formData.title}
                    onChange={(e) => {
                      setFormData({ ...formData, title: e.target.value })
                      setErrorTitle(false)
                    }}
                  />
                </AnimateDownChild>
                <AnimateDownChild className="mt-3">
                  <TextEditor
                    className="styled-editor white-bg !rounded-md"
                    editorRef={editorRef}
                    placeholder={sanitizedPrefill}
                    formData={formData}
                    height={100}
                    feedbackWidget={true}
                    setFormData={(data) => setFormData({ ...formData, content: data })}
                  />
                </AnimateDownChild>
                {/* TODO add user here */}

                {formData?.customInputValues && (
                  <AnimateDownChild>
                    <div className="mt-2 -mx-6 overflow-hidden rounded-lg">
                      <PostCreationCustomFields
                        isWidget={true}
                        selectedOptions={{ customInputValues: formData?.customInputValues } as any}
                        setSelectedOptions={(value: any) => {
                          return setFormData(value)
                        }}
                        activeCategory={activeCategory}
                        customInputFieldErrors={customInputFieldErrors}
                        setCustomInputFieldErrors={setCustomInputFieldErrors}
                      />
                    </div>
                  </AnimateDownChild>
                )}

                {!user && !email && (
                  <AnimateDownChild className="relative mt-2">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        className="w-4 h-4 secondary-svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                        <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                      </svg>
                    </div>
                    <input
                      id="title"
                      className={cn(
                        'pl-9 !rounded-md white-bg',
                        errorEmail &&
                          'ring-rose-200 dark:ring-offset-gray-800 dark:ring-rose-500/40 ring-offset-white ring-offset-1 ring-2'
                      )}
                      placeholder={
                        org?.settings?.anyoneCanSubmit
                          ? `Your email address (Optional)`
                          : `Your email address`
                      }
                      value={formData.email}
                      onChange={(e) => {
                        setFormData({ ...formData, email: e.target.value })
                        setErrorEmail(false)
                      }}
                    />{' '}
                  </AnimateDownChild>
                )}

                <AnimateDownChild className="flex justify-between w-full gap-3 mt-4 bottom-3">
                  <button
                    onClick={() => setActivePage('main')}
                    className="flex items-center justify-center px-2 py-1 bg-transparent rounded-md cursor-pointer unstyled-button main-transition dark:shadow-none dark:bg-transparent dashboard-secondary"
                  >
                    <ChevronLeftIcon className="w-5 h-5" />
                  </button>
                  <div className="flex gap-3">
                    <button
                      onClick={() => {
                        window.parent.postMessage(
                          {
                            target: 'FeaturebaseWidget',
                            data: { action: 'startScreenshot' },
                          },
                          '*'
                        )
                      }}
                      className="shadow-sm white-bg-hover"
                    >
                      {loadingImage ? (
                        <div className="w-4 h-4 secondary-svg mr-1.5">
                          <Loader />
                        </div>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4 secondary-svg mr-1.5"
                        >
                          <path d="M6 3a3 3 0 00-3 3v1.5a.75.75 0 001.5 0V6A1.5 1.5 0 016 4.5h1.5a.75.75 0 000-1.5H6zM16.5 3a.75.75 0 000 1.5H18A1.5 1.5 0 0119.5 6v1.5a.75.75 0 001.5 0V6a3 3 0 00-3-3h-1.5zM12 8.25a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zM4.5 16.5a.75.75 0 00-1.5 0V18a3 3 0 003 3h1.5a.75.75 0 000-1.5H6A1.5 1.5 0 014.5 18v-1.5zM21 16.5a.75.75 0 00-1.5 0V18a1.5 1.5 0 01-1.5 1.5h-1.5a.75.75 0 000 1.5H18a3 3 0 003-3v-1.5z" />
                        </svg>
                      )}
                      {t('take-a-screenshot')}
                    </button>
                    <button
                      onClick={() => submitFeedback()}
                      className="shadow-lg dashboard-primary"
                    >
                      {!loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4 -rotate-45 -mt-1 mr-1.5"
                        >
                          <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                        </svg>
                      ) : (
                        <div className="h-4 w-4 mr-1.5">
                          <Loader />
                        </div>
                      )}
                      {t('submit')}
                    </button>
                  </div>
                </AnimateDownChild>
              </AnimateDown>
            </div>
            <div>
              <CreatePostSimilarSuggestion
                widget={true}
                setActiveSubmissionId={(value) => {
                  // Open in new tab
                  window.open(getBoardUrl(org) + `/p/` + value)
                }}
                title={formData.title}
                content={formData.content}
              />
            </div>
          </div>
        ) : null}
        {activePage === 'success' ? (
          <div className="flex items-center justify-center p-6 h-[310px] flex-col text-center">
            <AnimateDown className="flex flex-col items-center justify-center w-full h-full">
              <AnimateDownChild>
                <p className="text-lg font-medium text-gray-600 dark:text-gray-50">
                  {t('thank-you-for-your-feedback')}
                </p>
              </AnimateDownChild>
              <AnimateDownChild>
                <p className="max-w-xs mt-3 text-sm text-gray-400 dark:text-foreground">
                  {newSubmission?.inReview && !user
                    ? t('your-feedback-is-being-reviewed-by-our-team')
                    : t('your-feedback-has-been-submitted')}
                </p>
              </AnimateDownChild>
              <AnimateDownChild className="flex flex-wrap items-center justify-center gap-3 mx-auto mt-5">
                <button onClick={() => setActivePage('main')} className="white-bg-hover">
                  {t('post-more')}
                </button>
                {org?.settings?.hideFeedbackBoard ? (
                  <button
                    onClick={() =>
                      window.parent.postMessage(
                        {
                          target: 'FeaturebaseWidget',
                          data: { action: 'closeWidget' },
                        },
                        '*'
                      )
                    }
                    className="dashboard-primary"
                  >
                    {t('close')}
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      window.open(getBoardUrl(org), '_blank')
                    }}
                    className="dashboard-primary"
                  >
                    {t('view-upvote-other-posts')}
                    <ExternalLinkIcon className="text-primary-foreground/70 ml-1.5" />
                  </button>
                )}
              </AnimateDownChild>
            </AnimateDown>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default FeedbackWidget

'use client'

import * as React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '@/lib/utils'
import SimpleTooltip from '../SimpleTooltip'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'

const labelVariants = cva(
  'text-sm dark:text-gray-100 text-gray-400 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
)

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants> & {
      optional?: boolean
      tooltip?: string
    }
>(({ className, optional, children, tooltip, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props}>
    {children}
    {optional && (
      <span className="text-foreground/60 dark:text-foreground/60 ml-1.5 text-[13px]">
        (Optional)
      </span>
    )}
    {tooltip && (
      <SimpleTooltip content={tooltip}>
        <QuestionMarkCircleIcon className="secondary-svg inline-block cursor-help ml-1.5" />
      </SimpleTooltip>
    )}
  </LabelPrimitive.Root>
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }

import * as React from 'react'
import * as HeroIcons from '@heroicons/react/solid'
import { IconList } from './iconType'
import { cn } from '@/lib'

interface IconPickerItemProps {
  icon: IconList
  size?: number
  noSize?: boolean
}

const IconPickerItem: React.FC<IconPickerItemProps> = ({ icon, size = 6, noSize }) => {
  const IconComponent = HeroIcons[icon as keyof typeof HeroIcons]

  if (!IconComponent) {
    console.warn(`Icon "${icon}" not found`)
    return null
  }

  return (
    <div className={cn(noSize ? '' : `!w-${size} !h-${size} !m-0 !p-0`)}>
      <IconComponent />
    </div>
  )
}

export { IconPickerItem }

import { useMemo } from 'react'
import { Editor, useEditor } from '@tiptap/react'
import { ExtensionKit } from '@/components/editor/extensions/extension-kit'
import { useDebouncedCallback } from 'use-debounce'
import {
  handleImageDrop,
  handleImagePaste,
} from '@/components/editor/extensions/HandleNonCommandImage/regular-upload-images'
import { onUpload, uploadFn } from '@/components/editor/image-upload'
import MarkdownIt from 'markdown-it'
import DOMPurify from 'dompurify'
import { TextSelection } from 'prosemirror-state'

declare global {
  interface Window {
    editor: Editor | null
  }
}

// Function to remove color from style attribute
const removeColorFromStyle = (element: HTMLElement) => {
  if (element.style.color) {
    element.style.removeProperty('color')
  }
  for (let i = 0; i < element.children.length; i++) {
    removeColorFromStyle(element.children[i] as HTMLElement)
  }
}

// Function to check if the image is from the CDN
const CDN_DOMAIN = 'fb-usercontent.fra1.cdn.digitaloceanspaces.com'
const isFromCDN = (url: string) => {
  try {
    const parsedUrl = new URL(url)
    return parsedUrl.hostname.includes(CDN_DOMAIN)
  } catch {
    return false
  }
}

export const useBlockEditor = (
  instanceId: string,
  content: string,
  setNewContent: (content: string) => any,
  compactMode: boolean,
  staticPlaceholderRefWrapper?: any,
  height?: number,
  editorRef?: any,
  dontAutoFocus?: boolean,
  changelog?: boolean
) => {
  const debouncedUpdates = useDebouncedCallback(async (editor: any) => {
    setNewContent(editor.getHTML())
  }, 100)

  const editor = useEditor({
    autofocus: false,
    onUpdate: ({ editor }) => {
      debouncedUpdates(editor)
    },
    extensions: [...ExtensionKit(instanceId, compactMode, staticPlaceholderRefWrapper, changelog)],
    editorProps: {
      handlePaste: (view, event) => {
        try {
          const clipboardData = event.clipboardData
          if (!clipboardData) {
            return false
          }

          const text = clipboardData.getData('text/plain')

          // Updated regex to handle optional titles in image syntax
          const imageMarkdownRegex = /!\[(.*?)\]\((\S+)(?:\s+["'](.+?)["'])*\)/g

          // Your CDN URL or domain
          const CDN_DOMAIN = 'fb-usercontent.fra1.cdn.digitaloceanspaces.com'

          // Function to check if the image is from the CDN
          const isFromCDN = (url: string) => {
            try {
              const parsedUrl = new URL(url)
              return parsedUrl.hostname.includes(CDN_DOMAIN)
            } catch {
              return false
            }
          }

          // Check if text exists
          if (text) {
            let match: RegExpExecArray | null
            let isHandled = false
            let lastIndex = 0

            // Define the fragment type
            type Fragment =
              | { type: 'text'; content: string }
              | { type: 'image'; attrs: { src: string; alt: string; title?: string } }

            const fragments: Fragment[] = []

            while ((match = imageMarkdownRegex.exec(text)) !== null) {
              isHandled = true

              // Text before the image
              const textBeforeImage = text.substring(lastIndex, match.index)
              if (textBeforeImage !== '') {
                fragments.push({ type: 'text', content: textBeforeImage })
              }

              const altText = match[1]
              const imageUrl = match[2]
              const title = match[3] || ''

              fragments.push({
                type: 'image',
                attrs: { src: imageUrl, alt: altText, title },
              })

              lastIndex = imageMarkdownRegex.lastIndex
            }

            // Text after the last image
            const textAfterLastImage = text.substring(lastIndex)
            if (textAfterLastImage !== '') {
              fragments.push({ type: 'text', content: textAfterLastImage })
            }

            if (isHandled) {
              event.preventDefault()

              // Store the current selection position
              let { from } = view.state.selection

              // Process each fragment sequentially
              fragments.forEach((fragment) => {
                if (fragment.type === 'text') {
                  // Convert Markdown text to HTML
                  const markdownIt = new MarkdownIt()
                  const html = markdownIt.render(fragment.content)

                  // Sanitize the HTML
                  const sanitizedHtml = DOMPurify.sanitize(html, {
                    ADD_TAGS: ['img'],
                    ADD_ATTR: ['src', 'alt', 'title'],
                  })

                  // Set selection at the current position
                  view.dispatch(
                    view.state.tr.setSelection(TextSelection.near(view.state.doc.resolve(from)))
                  )

                  // Insert the HTML
                  view.pasteHTML(sanitizedHtml)

                  // Update the position after insertion
                  from = view.state.selection.to
                } else if (fragment.type === 'image') {
                  const { attrs } = fragment

                  if (attrs) {
                    const { src } = attrs
                    const initialPosition = from

                    if (!isFromCDN(src)) {
                      // Convert base64 to Blob
                      onUpload(src).then((url) => {
                        // Insert the image node
                        const { state, dispatch } = view

                        // Set selection at the initial position
                        const tr = state.tr.setSelection(
                          TextSelection.near(state.doc.resolve(initialPosition))
                        )
                        dispatch(tr)

                        // Insert the image node
                        dispatch(
                          state.tr.insert(
                            initialPosition,
                            state.schema.nodes.image.create({ src: url })
                          )
                        )

                        // Update the position after insertion
                        from = view.state.selection.to
                      })

                      // Optionally, you can insert a placeholder or do nothing
                      return
                    }

                    // If the image is from the CDN, insert it directly
                    const { state, dispatch } = view

                    // Set selection at the initial position
                    const tr = state.tr.setSelection(
                      TextSelection.near(state.doc.resolve(initialPosition))
                    )
                    dispatch(tr)

                    // Insert the image node
                    dispatch(
                      state.tr.insert(initialPosition, state.schema.nodes.image.create({ src }))
                    )
                    dispatch(state.tr.scrollIntoView())

                    // Update the position after insertion
                    from = view.state.selection.to
                  } else {
                    console.error('Image attributes are undefined.')
                  }
                }
              })

              return true
            }
          }

          // Existing handleImagePaste handling remains unchanged
          const handled = handleImagePaste(view, event, uploadFn)
          if (handled) return true

          const htmlData = clipboardData.getData('text/html')

          // If HTML data is available, clean and sanitize it
          if (htmlData) {
            event.preventDefault()

            // Create a temporary div to parse the HTML
            const tempDiv = document.createElement('div')
            tempDiv.innerHTML = htmlData
            removeColorFromStyle(tempDiv)
            const cleanHtml = tempDiv.innerHTML

            // Sanitize the HTML
            const sanitizedHtml = DOMPurify.sanitize(cleanHtml, {
              ADD_TAGS: ['img'],
              ADD_ATTR: ['src', 'alt', 'title'],
            })

            // Insert the sanitized HTML into the editor
            view.pasteHTML(sanitizedHtml)
            return true
          } else if (text) {
            // Update the regex to include image syntax
            const markdownSyntaxRegex =
              /(^\s{0,3}(?:[#>-]|\d+\.|\*|\+|-|\!\[.*?\]\(.*?\))\s)|(\*\*|__|\*|_|`{1,3}|~{2}|!\[.*?\]\(.*?\))/m
            const isMarkdown = markdownSyntaxRegex.test(text)

            if (isMarkdown) {
              // Parse Markdown to HTML
              const markdownIt = new MarkdownIt()
              const html = markdownIt.render(text)

              // Sanitize the generated HTML
              const sanitizedHtml = DOMPurify.sanitize(html, {
                ADD_TAGS: ['img'],
                ADD_ATTR: ['src', 'alt', 'title'],
              })

              event.preventDefault()

              // Insert the sanitized HTML into the editor
              view.pasteHTML(sanitizedHtml)

              return true
            } else {
              // If it's plain text, let the default behavior handle it
              return false
            }
          }
        } catch (error) {
          console.error('Error handling paste event:', error)
          // Optionally, display an error message to the user
          return false
        }
        return false
      },
      handleDrop: (view, event, _slice, moved) => handleImageDrop(view, event, moved, uploadFn),
      attributes: {
        class: !compactMode || changelog ? 'installation-content' : '',
        spellcheck: 'true',
        tabindex: dontAutoFocus ? '-1' : '0',
        style: `min-height: ${height ? height : 100}px;  padding-bottom: ${12}px;`,
      },
    },
    onCreate: ({ editor }) => {
      if (editorRef) {
        editorRef.current = editor
      }
    },
    onBeforeCreate({ editor }) {
      if (editorRef) {
        editorRef.current = editor
      }
    },
    content: content,
  })

  const characterCount = editor?.storage.characterCount || { characters: () => 0, words: () => 0 }

  return { editor, characterCount }
}

import React from 'react'

const EmptyActivity: React.FC = () => {
  return (
    <div className="w-full mb-4">
      <div className="relative flex flex-col items-center justify-center space-y-6">
        <div className="flex flex-col items-center justify-center text-center">
          <h2 className="pt-12 font-semibold text-gray-600 dark:text-white">No activity yet</h2>
          <p className="max-w-xs mt-2 text-background-accent dark:text-foreground">
            This user hasn't yet created, upvoted or commented on any posts.
          </p>
        </div>
      </div>
      <div className="relative max-w-md px-4 mx-auto space-y-6 overflow-hidden mt-9">
        <div className="relative">
          <div className="relative flex items-end w-full p-5 border rounded-lg shadow-sm dark:shadow-lg border-gray-100/70 dark:border-border/20 bg-gray-50/50 dark:bg-secondary/90">
            <div className="">
              <div className="h-5 rounded-md w-52 bg-gray-600/10 dark:bg-border/80"></div>
            </div>
            <div className="flex ml-auto">
              <div className="relative w-6 h-6 rounded-full bg-gradient-to-b from-emerald-600 to-green-400"></div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="relative flex items-end w-full p-5 border rounded-lg shadow-sm dark:shadow-lg border-gray-100/70 dark:border-border/20 bg-gray-50/50 dark:bg-secondary/90">
            <div className="">
              <div className="h-5 rounded-md w-28 bg-gray-600/10 dark:bg-border/80"></div>
            </div>
            <div className="flex ml-auto">
              <div className="relative w-6 h-6 rounded-full bg-gradient-to-b from-rose-600 to-red-500"></div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="relative">
            <div className="relative flex items-end w-full p-5 border rounded-lg shadow-sm dark:shadow-lg border-gray-100/70 dark:border-border/20 bg-gray-50/50 dark:bg-secondary/90">
              <div className="">
                <div className="w-40 h-5 rounded-md bg-gray-600/10 dark:bg-border/80"></div>
              </div>
              <div className="flex ml-auto">
                <div className="relative w-6 h-6 rounded-full bg-gradient-to-b from-blue-600 to-sky-400"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptyActivity

import axiosClient from '../apiClient'

export function getUser() {
  return axiosClient.get('/v1/user')
}

export function loginWithPassword(data: {
  email: string
  password: string
  recaptchaToken: string
  type: 'admin' | 'customer'
}) {
  data.type = ['admin', 'customer'].includes(data.type) ? data.type : 'customer'
  return axiosClient.post(`/v1/auth/login`, JSON.stringify(data))
}

export function loginWithJWT(data: { jwt: string; return_to?: string }) {
  return axiosClient.get('/v1/auth/access/jwt', { params: { ...data } })
}

export function searchUsers(data: { query: string }) {
  return axiosClient.get(
    `/v1/organization/trackedUsers?q=${data.query}&limit=12&sortBy=lastActivity&page=1`
  )
}

export function loginWithToken(data: { token: string }) {
  return axiosClient.post('/v1/auth/tokenLogin', JSON.stringify(data))
}

export function getIdentifiedUser(data: { id: string }) {
  return axiosClient.get('/v1/user/getIdentifiedUser', { params: { ...data } })
}

export function registerWithPassword(data: {
  email: string
  password: string
  name: string
  recaptchaToken: string
  type: 'admin' | 'customer'
}) {
  data.type = ['admin', 'customer'].includes(data.type) ? data.type : 'customer'
  return axiosClient.post('/v1/auth/register', JSON.stringify(data))
}

export function updateUserData(data: { name: string; description: string }) {
  return axiosClient.patch('/v1/user', data)
}

export function deleteUser() {
  return axiosClient.post('/v1/user/delete')
}

export function updateUserProfilePic(data: FormData) {
  return axiosClient.post('/v1/user/profile', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function requestPasswordReset(data: {
  email: string
  recaptchaToken: string
  organizationId?: string
}) {
  return axiosClient.post('/v1/auth/forgot-password', data)
}

export function resetPassword(data: { password: string; token: string }) {
  return axiosClient.post('/v1/auth/reset-password', data)
}

export function logOut() {
  return axiosClient.get('/v1/auth/logout')
}

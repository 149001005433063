import { authenitcateModalAtom } from '@/atoms/authAtom'
import AnimateDown, { AnimateDownChild } from '@/components/AnimateDown'
import ChangelogCategory from '@/components/ChangelogCategory'
import { ContentModifier } from '@/components/ContentReplacer'
import FloatingChangelogComment from '@/components/FloatingChangelogComment'
import PublicBoardAuth from '@/components/PublicBoardAuth'
import Tinybird from '@/components/Tinybird'
import useElementSize from '@/components/useElementSize'
import { useChangelogsWithFiltering, useCurrentOrganization } from '@/data/organization'
import { useUser } from '@/data/user'
import { IChangelog, IChangelogFilters } from '@/interfaces/IChangelog'
import { IOrganization } from '@/interfaces/IOrganization'
import { cn, isLocalStorageAvailable } from '@/lib/utils'
import { XIcon } from '@heroicons/react/solid'
import { useAtom } from 'jotai'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'

export const getBoardUrl = (org: IOrganization) => {
  return org?.customDomain
    ? 'https://' + org?.customDomain
    : 'https://' + org?.name + '.featurebase.app'
}

const ChangelogPopup = ({
  name,
  alwaysShow,
  slug,
  trackerData,
}: {
  name: string
  alwaysShow: boolean
  slug?: string
  trackerData: {
    shownChangelogs: string[]
    unviewedChangelogs: string[]
  }
}) => {
  const { t, i18n } = useTranslation()

  const { org, mutateCurrentOrg } = useCurrentOrganization()
  const [filters, setFilters] = useState<IChangelogFilters>({
    sortBy: 'date:desc',
    changelogCategories: [],
    limit: 7,
    state: 'live',
    slug,
    locale: i18n.language === 'default' ? 'en' : i18n.language,
  })
  const [editorHeightRef, { width, height: editorHeight }] = useElementSize()
  const [authenitcateModal, setAuthenitacteModal] = useAtom(authenitcateModalAtom)
  const [renderStatistics, setRenderStatistics] = useState(false)

  const [isFreeOrg, setIsFreeOrg] = useState(false)

  const [parentWidth, setParentWidth] = useState(1000)

  const { changelogResults, mutateChangelogs } = useChangelogsWithFiltering(filters, org, true)
  const { user } = useUser()

  useEffect(() => {
    if (user) {
      mutateChangelogs()
    }
  }, [user])

  useEffect(() => {
    if (editorHeight && !isNaN(editorHeight)) {
      window.parent.postMessage(
        {
          target: 'FeaturebaseWidget',
          data: { action: 'popupHeightChanged', data: editorHeight },
        },
        '*'
      )
    }
  }, [editorHeight])

  const syncStorage = () => {
    if (!isLocalStorageAvailable()) return
    window.parent.postMessage(
      {
        target: 'FeaturebaseWidget',
        data: {
          action: 'updateHasUpdates',
          data: localStorage?.getItem('FBVisitedChangelogsTracker-' + org?.name),
        },
      },
      '*'
    )
  }

  useEffect(() => {
    if (!isLocalStorageAvailable()) return
    if (org) {
      if (org?.plan === 'free' && new Date(org?.createdAt) > new Date('2023-08-13')) {
        setIsFreeOrg(true)
      }
      if (changelogResults) {
        const storageKey = 'FBVisitedChangelogsTracker-' + org?.name
        const localData = localStorage?.getItem(storageKey)
        const updatedChangelogs = changelogResults.map((c: IChangelog) => c.id)
        if (localData === null) {
          const oldItem = localStorage?.getItem('FBVisitedChangelogsTracker')
          if (oldItem) {
            // If the old item exists, migrate it to the new storage key
            localStorage?.setItem(storageKey, oldItem)
            localStorage?.removeItem('FBVisitedChangelogsTracker')
            syncStorage()
          } else {
            localStorage?.setItem(
              storageKey,
              JSON.stringify({ shownChangelogs: updatedChangelogs, unviewedChangelogs: [] })
            )
            syncStorage()
          }
        } else {
          const parsedData = JSON.parse(localData)
          let storedChangelogs = parsedData?.shownChangelogs || []
          let unviewedChangelogs = updatedChangelogs.filter((c) => !storedChangelogs.includes(c))

          localStorage?.setItem(
            storageKey,
            JSON.stringify({
              shownChangelogs: storedChangelogs?.filter(
                (item: string) => !unviewedChangelogs?.includes(item)
              ),
              unviewedChangelogs,
            })
          )
          syncStorage()
        }
      }
    }
  }, [changelogResults, org])

  useEffect(() => {
    if (!changelogResults) return

    syncStorage()
  }, [changelogResults])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        window.parent.postMessage(
          {
            target: 'FeaturebaseWidget',
            data: { action: 'closeWidget' },
          },
          '*'
        )
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault()
      if (!e.data || e.data.target !== 'FeaturebaseWidget') return
      switch (e.data.data.action) {
        case 'viewportResized':
          setParentWidth(e?.data?.data?.width)
          break
        case 'largeWidgetOpened':
          setRenderStatistics(true)
          break
        case 'unviewLatestChangelog':
          if (isLocalStorageAvailable()) {
            const storageKey = 'FBVisitedChangelogsTracker-' + (org?.name || '')

            const storageData = localStorage.getItem(storageKey)
            if (storageData) {
              const parsedData = JSON.parse(storageData)
              const shownChangelogs = parsedData?.shownChangelogs || []
              const unviewedChangelogs = parsedData?.unviewedChangelogs || []

              if (shownChangelogs.length > 0) {
                // Remove the last element from the shownChangelogs array
                const lastElement = shownChangelogs.pop()

                // Add the last element to the unviewedChangelogs array
                unviewedChangelogs.push(lastElement)

                // Update the parsedData with modified arrays
                parsedData.shownChangelogs = shownChangelogs
                parsedData.unviewedChangelogs = unviewedChangelogs

                // Serialize the modified object back to a JSON string and store it in the localStorage
                const updatedStorageData = JSON.stringify(parsedData)
                localStorage.setItem(storageKey, updatedStorageData)
              }
            }
          }
          break
        case 'setAsViewed':
          setAllAsViewed()
          break
        default:
          return
      }
    }

    window?.addEventListener('message', handler)

    return () => window.removeEventListener('message', handler)
  }, []) // empty array => run only once

  const isChangelogViewed = (changelogId: string) => {
    if (!isLocalStorageAvailable()) return false
    const storageData = localStorage?.getItem('FBVisitedChangelogsTracker-' + org?.name || '')
    if (storageData) {
      const parsedData = JSON.parse(storageData)
      return parsedData?.shownChangelogs?.includes(changelogId)
    } else {
      return false
    }
  }

  if (isFreeOrg) {
    return (
      <div>
        <div className="p-6 " ref={editorHeightRef}>
          <p className="text-lg font-medium text-gray-600 dark:text-gray-50">
            Not available with the free plan
          </p>
          <p className="max-w-xs mt-1 text-sm text-gray-400 dark:text-foreground">
            Please upgrade your account to use this feature.
          </p>
        </div>
      </div>
    )
  }

  const isMobile = parentWidth <= 640
  // const reducedOpacity = chroma(org?.color)
  //   .alpha(0.15)
  //   .css()

  const setAllAsViewed = () => {
    if (isLocalStorageAvailable()) {
      const storageData = JSON.parse(
        localStorage?.getItem('FBVisitedChangelogsTracker-' + org?.name) ||
          localStorage?.getItem('FBVisitedChangelogsTracker') ||
          '{}'
      )
      // If viewedChangelogs array does not exist, initialize it

      changelogResults?.forEach((changelog) => {
        if (!storageData?.shownChangelogs?.includes(changelog.id)) {
          storageData.shownChangelogs.push(changelog.id)
          // Remove the viewed changelog id from the unviewedChangelogs array
          storageData.unviewedChangelogs = storageData.unviewedChangelogs.filter(
            (id: string) => id !== changelog.id
          )
          localStorage?.setItem(
            'FBVisitedChangelogsTracker-' + org?.name,
            JSON.stringify(storageData)
          )
        }
      })

      syncStorage()
    }
  }

  return (
    <div
      className={cn(
        'max-w-[608px] bg-white dark:bg-background overflow-auto overflow-x-hidden overscroll-contain custom-scrollbar scrollbar-thumb-gray-100/70 scrollbar-track-gray-50 dark:scrollbar-thumb-border dark:scrollbar-track-secondary/80 h-[700px] relative  ',
        isMobile && 'max-h-full'
      )}
      ref={editorHeightRef}
    >
      {renderStatistics && (
        <Tinybird
          customValues={{ tb_changelog_id: changelogResults?.[0]?.id }}
          module="changelog"
        />
      )}

      <button
        onClick={() => {
          window.parent.postMessage(
            {
              target: 'FeaturebaseWidget',
              data: { action: 'closeWidget' },
            },
            '*'
          )
          setAllAsViewed()
        }}
        className="fixed z-50 cursor-pointer unstyled-button right-5 top-5"
      >
        <XIcon className="relative w-5 h-5 secondary-svg" />
      </button>
      <AnimateDown className="mb-[15px]">
        {!filters?.slug && (
          <AnimateDownChild
            className={cn(
              'flex text-left flex-col bg-gradient-to-b from-gray-50 dark:from-secondary items-start justify-center p-8 pb-6',
              isMobile && 'p-4 py-6'
            )}
          >
            <h3 className="text-2xl font-semibold text-gray-600 dark:text-gray-50">
              {name ? t('welcome-back-name', { name }) : t('welcome-back')}! 👋
            </h3>
            <p className="mt-3 font-medium text-gray-400 dark:text-foreground">
              {t('here-is-what-we-added-while-you-were-away')}
            </p>
          </AnimateDownChild>
        )}
        {changelogResults?.map((changelog, index) => {
          if (isChangelogViewed(changelog.id)) {
            if (alwaysShow || filters?.slug) {
            } else {
              return null
            }
          }
          return (
            <AnimateDownChild
              key={changelog.id}
              className={cn(
                'pb-6',
                isMobile && 'pb-4',
                org?.name !== 'reword' && 'border-t border-gray-100/60 dark:border-border'
              )}
            >
              <div className="relative">
                {changelog?.featuredImage && (
                  <img
                    src={
                      `https://featurebase.app/cdn-cgi/image/width=900,quality=90,format=webp/` +
                      changelog.featuredImage
                    }
                    className={cn(
                      'object-cover w-full h-full mb-4 rounded-none',
                      org?.name === 'reword' ? 'px-4' : ''
                    )}
                  />
                )}
              </div>
              {!changelog.featuredImage && <div className={cn('mb-6', isMobile && 'mb-4')}></div>}
              <p
                id="title"
                className={cn(
                  'text-xl px-6 font-bold text-gray-600 dark:text-white',
                  isMobile && 'px-4'
                )}
              >
                {changelog?.title}
              </p>
              {changelog?.changelogCategories && (
                <div
                  className={cn('flex mt-2 mb-3 px-6  flex-wrap items-center', isMobile && 'px-4')}
                >
                  {changelog?.changelogCategories?.map((category) => (
                    <div key={category.name} className="mt-1 mr-2">
                      <ChangelogCategory small={true} category={category} />
                    </div>
                  ))}
                </div>
              )}
              {/* <div
                      dangerouslySetInnerHTML={{ __html: changelog?.content || '' }}
                      className="text-sm mt-1.5 text-gray-500 dark:text-foreground changelog widget"
                    ></div> */}
              <div
                className={cn(
                  'changelog relative px-6  text-gray-500/95 dark:text-foreground ',
                  isMobile && 'px-4'
                )}
              >
                <div className="installation-content">
                  <ContentModifier allowAllIframes={true} content={changelog?.content} />
                </div>
                {!org?.settings?.disableChangelogCollaboration && (
                  <FloatingChangelogComment
                    unOpenedChangelog={false}
                    widget={true}
                    mutateChangelogs={mutateChangelogs}
                    changelog={changelog}
                  />
                )}
              </div>
            </AnimateDownChild>
          )
        })}

        <div className="relative">
          {!org?.whitelabel && (
            <a
              href={`https://featurebase.app?utm_source=${org?.name}&utm_medium=changelog-popup&utm_campaign=powered-by&utm_id=${org?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <span
                className={cn(
                  'text-[10.5px] z-50 fixed right-6 bottom-20 dark:bg-secondary rounded-tl bg-gray-50/90 pr-2 pl-1.5 pt-0.5 backdrop-blur-lg transform-gpu justify-center main-transition hover:text-gray-600 text-gray-400 dark:text-foreground/80 dark:hover:text-gray-100 flex items-center cursor-pointer font-semibold',
                  // user && 'bottom-0 -right-1 pr-2.5 max-w-[160px]',
                  org?.settings?.disableChangelogCollaboration && !org?.settings?.hideFeedbackBoard
                    ? 'bottom-[74.59px]'
                    : 'bottom-0 -right-1 pr-2.5 max-w-[160px]'
                )}
              >
                {t('powered-by-featurebase')}
              </span>
            </a>
          )}
          {org?.settings?.disableChangelogCollaboration && !org?.settings?.hideFeedbackBoard && (
            <AnimateDownChild className="fixed inset-x-0 bottom-0 gap-2 p-6 py-5 border-t bg-white/80 backdrop-blur-lg transform-gpu dark:border-dark-accent dark:bg-secondary">
              <div className="flex items-center justify-between gap-2 ">
                <div>
                  <p
                    className={cn(
                      'dark:text-foreground text-sm  text-gray-400 font-medium',
                      isMobile && 'max-w-[260px] '
                    )}
                  >
                    Vote & suggest what we should add next 👉
                  </p>
                </div>
                <a
                  href={getBoardUrl(org)}
                  target="_blank"
                  rel="noreferrer"
                  className="flex-shrink-0"
                >
                  <button
                    className="border rounded-lg shadow-md branded-btn border-white/10"
                    style={{ backgroundColor: org?.color }}
                  >
                    Share feedback
                  </button>
                </a>
              </div>
            </AnimateDownChild>
          )}
        </div>
      </AnimateDown>
      {org && (
        <PublicBoardAuth
          isOpen={authenitcateModal}
          setIsOpen={setAuthenitacteModal}
          callback={() => {
            mutateCurrentOrg()
          }}
          widget={true}
        />
      )}
    </div>
  )
}

export default ChangelogPopup

import ScreenshotPlaceholder from '@/components/ScreenshotPlaceholder'
import { cn } from '@/lib/utils'
import { Node } from '@tiptap/pm/model'
import { Editor, NodeViewWrapper } from '@tiptap/react'
import { useCallback, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

interface ImageBlockViewProps {
  editor: Editor
  getPos: () => number
  node: Node & {
    attrs: {
      src: string
      type?: 'image' | 'video'
    }
  }
  updateAttributes: (attrs: Record<string, string>) => void
}

export const ImageBlockView = (props: ImageBlockViewProps) => {
  const { editor, getPos, node } = props
  const mediaWrapperRef = useRef<HTMLDivElement>(null)
  const { src, type = 'image' } = node.attrs
  const [isProcessing, setIsProcessing] = useState(false)

  const wrapperClassName = cn(
    node.attrs.align === 'left' ? 'ml-0' : 'ml-auto',
    node.attrs.align === 'right' ? 'mr-0' : 'mr-auto',
    node.attrs.align === 'center' && 'mx-auto'
  )

  const onClick = useCallback(() => {
    editor.commands.setNodeSelection(getPos())
  }, [getPos, editor.commands])

  const handleImageError = (err: any) => {
    if (src.includes('/ss-')) {
      setIsProcessing(true)
    }
  }

  const renderMedia = () => {
    if (isProcessing) {
      return <ScreenshotPlaceholder />
    }

    if (type === 'video') {
      return (
        <ReactPlayer
          url={src}
          controls
          width="100%"
          height="100%"
          onClick={onClick}
          config={{
            file: {
              attributes: {
                style: { objectFit: 'contain' },
              },
            },
          }}
        />
      )
    }
    return <img className="block" src={src} alt="" onClick={onClick} onError={handleImageError} />
  }

  return (
    <NodeViewWrapper>
      <div className={wrapperClassName} style={{ width: node.attrs.width }}>
        <div contentEditable={false} ref={mediaWrapperRef}>
          {renderMedia()}
        </div>
      </div>
    </NodeViewWrapper>
  )
}

export default ImageBlockView

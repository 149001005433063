'use client'

import * as React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { AnimatePresence, MotionConfig, motion } from 'framer-motion'
import useMeasure from 'react-use-measure'
import { cn } from '@/lib/utils'
import { useMediaQuery } from '@/lib/hooks'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverClose = PopoverPrimitive.Close

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    open?: boolean
    width?: number
    zIndex?: number
    disableResizeAnimation?: boolean
  }
>(
  (
    {
      className,
      align = 'end',
      side = 'bottom',
      sideOffset = 4,
      open,
      width,
      zIndex,
      disableResizeAnimation,
      ...props
    },
    ref
  ) => {
    const isMobile = useMediaQuery('(max-width: 767px)')

    return (
      <MotionConfig transition={{ duration: 0.07, type: 'tween' }}>
        <PopoverPrimitive.Portal>
          <PopoverPrimitive.Content
            ref={ref}
            align={align}
            side={side}
            sideOffset={sideOffset}
            // avoidCollisions={false}
            style={{
              zIndex: zIndex ? zIndex : 200,
            }}
            onOpenAutoFocus={(e) => isMobile && e.preventDefault()}
            className={cn(
              'rounded-md focus:outline-none focus:outline-0 dropdown-background pl-0 overflow-auto custom-scrollbar text-base sm:text-sm  text-gray-400 dark:text-foreground',
              'animate-in fade-in-0 duration-150 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
              className
            )}
            {...props}
          >
            {disableResizeAnimation ? (
              props.children
            ) : (
              <ResizablePanel>{props.children}</ResizablePanel>
            )}
          </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
      </MotionConfig>
    )
  }
)
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverTrigger, PopoverContent, PopoverClose }

function ResizablePanel({ children }: any) {
  let [ref, { height }] = useMeasure()

  return (
    <motion.div animate={{ height: height || 'auto' }} className="relative overflow-hidden">
      <AnimatePresence initial={false}>
        <motion.div
          key={JSON.stringify(children, ignoreCircularReferences())}
          className={height ? 'absolute w-full' : 'relative w-full'}
        >
          <div ref={ref}>{children}</div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  )
}

export const ignoreCircularReferences = () => {
  const seen = new WeakSet()
  return (key: any, value: any) => {
    if (key.startsWith('_')) return // Don't compare React's internal props.
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) return
      seen.add(value)
    }
    return value
  }
}

import { useSimilarPostsAndArticles } from '@/data/submission'
import React, { useEffect, useMemo, useState } from 'react'
import SimilarSubmissionsList from './AnimatedSimilarResultList'
import { SparklesIcon } from '@heroicons/react/solid'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import useSubmissionUrl from '@/hooks/submissionUrlSyncer'
import { cn } from '@/lib/utils'
import { meilisearchClientAtom, searchKeyAtom } from '@/atoms/orgAtom'
import { useAtom } from 'jotai'
import { useDebouncedCallback } from 'use-debounce'
import { useCurrentOrganization } from '@/data/organization'
import { getAlgoliaKey } from 'network/lib/organization'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import isEqual from 'lodash/isEqual'

const CONFIDENCE_LIMIT = 0.7

const CreatePostSimilarSuggestion: React.FC<{
  title: string
  content: string
  setActiveSubmissionId: React.Dispatch<React.SetStateAction<string>>
  widget?: boolean
}> = ({ title, content, setActiveSubmissionId, widget }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchKey, setSearchKey] = useAtom(searchKeyAtom)
  const { data: similarData } = useSimilarPostsAndArticles(searchTerm, searchKey)
  const { org } = useCurrentOrganization()
  const [meilisearchClient, setMeilisearchClient] = useAtom(meilisearchClientAtom)

  const { setUrl } = useSubmissionUrl()
  const { t } = useTranslation()

  const [data, setData] = useState<{ articles: any[]; posts: any[] }>({
    articles: [],
    posts: [],
  })

  useEffect(() => {
    if (similarData?.posts && similarData?.articles) {
      const newData = {
        articles: similarData.articles,
        posts: similarData.posts,
      }

      if (!isEqual(newData, data)) {
        setData(newData)
      }
    }
  }, [similarData])

  const debouncedSearch = useDebouncedCallback((newTitle: string, newContent: string) => {
    setSearchTerm(newTitle.slice(0, 60) + newContent.slice(0, 60))
  }, 800) // 300ms debounce

  useEffect(() => {
    debouncedSearch(title, content)
  }, [title, content, debouncedSearch])

  useEffect(() => {
    if (!searchKey || !meilisearchClient) {
      getAlgoliaKey()
        .then((res) => {
          if (res?.data?.publicKey) {
            setSearchKey(res.data.publicKey)
            setMeilisearchClient(
              instantMeiliSearch(
                process.env.NEXT_PUBLIC_MEILISEARCH_HOST as string,
                res.data.publicKey
              ).searchClient
            )
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [searchKey, meilisearchClient])

  return (
    <AnimatePresence initial={false}>
      {title &&
      [...data.articles, ...data.posts]?.find((item: any) => {
        if (!item?.articleId) {
          return item?._rankingScoreDetails?.vectorSort?.similarity >= CONFIDENCE_LIMIT
        } else if (item?._rankingScoreDetails) {
          return item?._rankingScoreDetails?.vectorSort?.similarity >= 0.5
        }
        return false
      }) ? (
        <motion.div
          initial={{ height: 0, overflow: 'hidden' }}
          animate={{ height: 'auto', overflow: 'auto' }}
          exit={{ height: 0, overflow: 'hidden' }}
          transition={{ duration: widget ? 0 : 0.4, ease: 'easeInOut' }}
          className={cn(
            widget
              ? 'bg-gradient-to-b from-white/60 px-1 dark:from-dark-accent/30 shadow-inner dark:shadow-gray-700/30 shadow-gray-200/20'
              : 'border-t rounded-b-lg shadow-inner bg-gray-100/30 dark:bg-gray-900/30 dark:border-white/5 scrollbar-none'
          )}
        >
          <p
            className={cn(
              'flex px-[8px] py-3 pb-1.5 items-center text-xs font-medium',
              widget && ' white-bg border-0 border-y p-2 opacity-75 px-[17px] -mx-1'
            )}
          >
            {/* <SparklesIcon className={cn('secondary-svg h-3.5 w-3.5 mr-2.5', widget && 'mr-2')} /> */}
            {/* {t('similar-posts')} */}

            <span
              className={cn(
                !widget
                  ? 'px-1.5 flex items-center py-1 rounded-md text-accent-foreground/80 bg-white shadow-sm dark:bg-accent/[7%]'
                  : 'px-1.5 flex items-center'
              )}
            >
              <SparklesIcon
                className={cn('text-accent-foreground/50 h-3.5 w-3.5 mr-1.5', widget && 'mr-1.5')}
              />{' '}
              {org?.activeHelpCenter?.enabled
                ? t('similar-articles-and-posts')
                : t('similar-posts')}
            </span>
          </p>
          <div className={cn(widget ? 'mt-0.5' : '')}>
            <SimilarSubmissionsList
              setActiveSubmissionId={(id) => {
                setActiveSubmissionId(id)
              }}
              setActiveSlugId={(slug: string) => {
                setUrl(slug)
              }}
              widget={widget}
              submissionView={false}
              confidenceLimit={CONFIDENCE_LIMIT}
              similarSubmissions={data.posts}
              similarArticles={data.articles}
            />
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default CreatePostSimilarSuggestion
